import { useLocation } from "@reach/router";
import { getConfig } from "@src/config";
import { GQLVehicleSearchResultVehicleFragment } from "@src/graphql.apollo.generated";
import Images from "@src/shared/assets/images/images";
import { CarTypeBadge } from "@src/shared/carTypeBadge/CarTypeBadge";
import useSearchFilter from "@src/shared/context/searchfilter/useSearchFilter";
import { DebugBadge } from "@src/shared/debugBadge/DebugBadge";
import InfoIcon from "@src/shared/infoIcon/InfoIcon";
import PriceInfo from "@src/shared/priceInfo/PriceInfo";
import FormattedCurrency from "@src/shared/utils/FormattedCurrency";
import { generateVehicleDetailUrl } from "@src/shared/vehicleDetail/generateVehicleDetailUrl";
import { format, parseISO } from "date-fns";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import * as sc from "./VehicleItem.sc";

interface IVehicleItemProps {
    vehicle: GQLVehicleSearchResultVehicleFragment;
}
const VehicleItem: React.FunctionComponent<IVehicleItemProps> = ({ vehicle }) => {
    const intl = useIntl();
    const { filter } = useSearchFilter();
    const location = useLocation();
    const showBadge = getConfig("showVehicleTypeBadge");

    const carImage = vehicle.images?.[0]?.uri ? vehicle.images?.[0].uri : Images.CarAvatar;

    return (
        <sc.Root to={generateVehicleDetailUrl(vehicle, filter, location)}>
            {getConfig("scope").domain === "lease-me.porschebank.at" && !vehicle.firstRegistration && <CarTypeBadge />}
            <sc.ImageMobile
                defaultImage={Boolean(!vehicle.images?.[0]?.uri)}
                alt={intl.formatMessage({
                    id: "leaseme.vehicleItem.smallImage",
                    defaultMessage: "Fahrzeug Vorschaubild mobile",
                })}
                src={carImage}
                loadLazy={true}
            />
            <sc.ImageLarge
                defaultImage={Boolean(!vehicle.images?.[0]?.uri)}
                alt={intl.formatMessage({
                    id: "leaseme.vehicleItem.largeImage",
                    defaultMessage: "Fahrzeug Vorschaubild groß",
                })}
                src={carImage}
                loadLazy={true}
                width={250}
                height={239}
            />
            <sc.TextContainer>
                <sc.Title>
                    {vehicle.model}
                    {showBadge && <DebugBadge type={vehicle?.type} />}
                </sc.Title>
                <sc.Row>
                    {(getConfig("scope").domain === "car4me.si" || getConfig("scope").domain === "car4me.porschefinance.hu") && (
                        <sc.Text>{vehicle.dealer?.name}</sc.Text>
                    )}

                    <sc.Text>
                        <sc.LocationIcon src={Images.Location} alt="location" marginLeft={getConfig("scope").domain === "car4me.si"} />
                        {vehicle.dealer?.zip} {vehicle.dealer?.city}
                        {vehicle.location?.province && `, ${vehicle.location?.province}`}
                    </sc.Text>
                </sc.Row>

                <sc.VerticalAlignmentWrapper>
                    <sc.VehicleData>
                        <sc.Column>
                            <sc.ListItem>
                                {`${intl.formatMessage({
                                    id: "leaseme.firstRegistration.short.label",
                                    defaultMessage: "EZ",
                                })} ${vehicle.firstRegistration ? format(parseISO(vehicle.firstRegistration), "MM/yyyy") : "-"}`}
                            </sc.ListItem>
                            <sc.ListItem>
                                {`${vehicle.mileage ? intl.formatNumber(vehicle.mileage) : "-"} ${intl.formatMessage({
                                    id: "leaseme.km",
                                    defaultMessage: "km",
                                })} ${getConfig("scope").domain === "lease-me.porschebank.at" ? "*" : ""}`}
                            </sc.ListItem>
                        </sc.Column>
                        <sc.Column>
                            <sc.ListItem>
                                {vehicle.powerPS
                                    ? `${intl.formatMessage(
                                          {
                                              id: "leaseme.powerValue",
                                              defaultMessage: "{powerPS} PS",
                                          },
                                          {
                                              powerPS: vehicle.powerPS,
                                          },
                                      )} (${Math.floor(vehicle.powerPS * 0.74)} kw)`
                                    : "\u00A0"}
                            </sc.ListItem>
                            <sc.ListItem>{vehicle.fuel?.text ?? "\u00A0"}</sc.ListItem>
                        </sc.Column>
                    </sc.VehicleData>

                    <sc.PriceAndLinkWrapper>
                        <sc.PriceWrapper>
                            <PriceInfo
                                price={vehicle.rate}
                                priceInfoText={intl.formatMessage({
                                    id: "leaseme.priceInfo.message",
                                    defaultMessage: "Mehr Informationen zum Finanzierungsangebot auf der Detailseite.",
                                })}
                            />
                            <sc.DownPayment>
                                <sc.DownPaymentPrice>
                                    <FormattedCurrency value={vehicle.downPayment} />
                                </sc.DownPaymentPrice>
                                <span>
                                    <FormattedMessage id="leaseme.downPayment.label" defaultMessage="Eigenleistung" />
                                </span>
                                <InfoIcon
                                    alignment="right"
                                    infoText={intl.formatMessage(
                                        {
                                            id: "leaseme.downPayment.message",
                                            defaultMessage: "{financeType, select, leasing {{downPaymentLeasing}} other {{downPaymentCredit}}}",
                                        },
                                        {
                                            financeType: filter.financeType,
                                            downPaymentLeasing: intl.formatMessage({
                                                id: "leaseme.downPayment.message.leasing",
                                                defaultMessage:
                                                    "Die Eigenleistung ist hier ein vorzeitig rückzahlbares Depot. Das heißt, dass die Einmalzahlung auf die vereinbarte Laufzeit aufgeteilt wird. Damit verringert sich das monatliche Leasingentgelt. Die Eigenleistung darf maximal 50% des Fahrzeugpreises netto betragen.",
                                            }),
                                            downPaymentCredit: intl.formatMessage({
                                                id: "leaseme.downPayment.message.credit",
                                                defaultMessage:
                                                    "Durch eine Anzahlung reduzieren sich Ihre monatlichen Raten. Möglich ist eine Anzahlung bis zu 40% des Kaufpreises.",
                                            }),
                                        },
                                    )}
                                />
                            </sc.DownPayment>
                        </sc.PriceWrapper>
                    </sc.PriceAndLinkWrapper>
                </sc.VerticalAlignmentWrapper>
                <sc.DwaVehicleLinkContainer>
                    <sc.VehicleLink>
                        <sc.VehicleLinkText>
                            <FormattedMessage id="leaseme.vehicleDetails" defaultMessage="Fahrzeugdetails" />
                        </sc.VehicleLinkText>
                    </sc.VehicleLink>
                </sc.DwaVehicleLinkContainer>
            </sc.TextContainer>
        </sc.Root>
    );
};

export default VehicleItem;
