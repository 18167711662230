import useDisplayName from "@src/shared/context/displayname/useDisplayName";
import useSearchFilter from "@src/shared/context/searchfilter/useSearchFilter";
import useSearchResult from "@src/shared/context/searchresult/useSearchResult";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import * as sc from "./FilterToolbar.sc";

const FilterToolbar: React.FunctionComponent = () => {
    const { usedOptionalFilter, resetOptionalFilter, resetAllOptionalFilters } = useSearchFilter();
    const { getDisplayName } = useDisplayName();
    const searchResult = useSearchResult();
    const noResults = searchResult.vehicles?.length === 0;

    return (
        <sc.FilterToolbarContainer>
            {noResults && (
                <sc.NoResultsText>
                    <FormattedMessage
                        id="leaseme.filter.noResult"
                        defaultMessage="Derzeit sind keine Fahrzeuge in der gewünschten Kombination
                        vorhanden. Versuchen Sie es erneut mit weniger oder
                        veränderten Suchkriterien."
                    />
                </sc.NoResultsText>
            )}
            {usedOptionalFilter && usedOptionalFilter.length > 0 && (
                <sc.FilterToolbar>
                    <sc.ChipsWrapper>
                        {usedOptionalFilter?.map((usedFilter) => (
                            <sc.FilterChip key={`${usedFilter.filter}-${usedFilter.value}`}>
                                {getDisplayName(usedFilter.filter, usedFilter.value)}
                                <sc.CancelSingleOptionIcon onClick={() => resetOptionalFilter?.(usedFilter)} />
                            </sc.FilterChip>
                        ))}
                    </sc.ChipsWrapper>
                    <sc.ResetButton onClick={() => resetAllOptionalFilters?.()}>
                        <sc.ResetButtonText>
                            <FormattedMessage id="leaseme.filter.resetAll" defaultMessage="Alle Filter zurücksetzen" />
                        </sc.ResetButtonText>
                        <sc.CancelIcon />
                    </sc.ResetButton>
                </sc.FilterToolbar>
            )}
        </sc.FilterToolbarContainer>
    );
};

export default FilterToolbar;
